exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-facho-js": () => import("./../../../src/pages/a-facho.js" /* webpackChunkName: "component---src-pages-a-facho-js" */),
  "component---src-pages-estudio-facho-js": () => import("./../../../src/pages/estudio-facho.js" /* webpackChunkName: "component---src-pages-estudio-facho-js" */),
  "component---src-pages-galpao-js": () => import("./../../../src/pages/galpao.js" /* webpackChunkName: "component---src-pages-galpao-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intuitiva-js": () => import("./../../../src/pages/intuitiva.js" /* webpackChunkName: "component---src-pages-intuitiva-js" */),
  "component---src-templates-intuitiva-article-js": () => import("./../../../src/templates/intuitiva-article.js" /* webpackChunkName: "component---src-templates-intuitiva-article-js" */),
  "component---src-templates-intuitiva-edition-js": () => import("./../../../src/templates/intuitiva-edition.js" /* webpackChunkName: "component---src-templates-intuitiva-edition-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

